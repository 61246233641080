import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable, of, switchMap, throwError} from 'rxjs';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';
import {MyToastrService} from 'app/_services/toastr.service';

@Injectable()
export class AuthService {
    public _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        public toast: MyToastrService,
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/users/authenticator', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                // this.accessToken = response.accessToken;
                if (response.status !== 'Failed') {
                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response;
                    return of(response);
                }
                // Return a new observable with the response
                return of(response)
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(data: any): Observable<any> {
        console.log("user added", data)
        if (data["[object Object]"]) {
            delete data["[object Object]"]
        }
        data['dob'] = "1997-05-10"
        data['reporting_manager'] = ""
        data['location'] = ""
        data['address'] = ""
        data['ratings'] = ""
        data['department'] = ""
        data['platform'] = "hmc"
        data.roles = 'General User'
        data.status = 'Pending'
        console.log(data)
        var call = "/api/users/registerUser"
        var method = 'post'

        if (data._id) {
            call = "/api/users"
            method = 'put'
        }
        data.platform = "hmc"

        return this._httpClient.post(call, data).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                // this.accessToken = response.accessToken;
                if (response && ((method == 'post' && response.status.toLowerCase() == 'success') || (method == 'put' && response._id))) {
                    if (method == 'post') {
                        this.toast.sToast('success', 'Your request has been registered successfully, You will receive an confirmation mail with password once your request is approved');
                    } else {
                        this.toast.sToast('success', 'User Updated successfully')
                    }


                } else {
                    if (response.msg) {
                        this.toast.sToast('error', response.msg)
                    } else {
                        this.toast.sToast('error', "Something went wrong please try again later")
                    }
                }
                // Return a new observable with the response
                return of(response)
            })
        );

    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
