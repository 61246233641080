<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel($event)" #notificationsOrigin>
    <mat-icon [svgIcon]="'heroicons_outline:information-circle'"></mat-icon>
</button>

<s-modal id="releaseNotes">
    <div class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row" *ngIf="releaseNotes">
        <div class="w-1/3 h-[80vh] max-w-md rounded-l-3xl
     bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4" fuseScrollbar>
            <p class="mb-4 font-bold text-xl tracking-wider">{{releaseNotes.title}}</p>
            <span
                class="bg-gradient-to-r from-indigo-400 to-cyan-400 text-black text-xs font-bold me-2 px-2.5 py-1 rounded-full">{{releaseNotes.type}}</span>
            <span class="text-sm font-bold tracking-wider uppercase">{{releaseNotes.date}}</span>
            <div class="bg-blue-600/80 rounded-2xl p-3 mt-4">
                <p class="mb-3 text-sm">
                    New Features, Enhancements and Bug Fixes.
                </p>
            </div>
            <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px" class="mt-10">
        </div>
        <div class="w-2/3 h-[80vh] rounded-r-3xl bg-card px-4 py-4 overflow-y-auto" fuseScrollbar>
            <button class="absolute right-2 z-10 mini-form-field" mat-icon-button aria-label="close modal icon"
                    matTooltip="Close" (click)="modalService.close('releaseNotes');">
                <mat-icon>close</mat-icon>
            </button>
            <div
                class="col-span-12 space-y-12 relative px-4 ml-5 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-500 before:dark:bg-gray-700">
                <ng-container *ngFor="let sec of releaseNotes.sections">
                    <div
                        class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-400">
                        <h3 class="text-xl font-semibold tracking-wide">{{sec.title}}</h3>
                        <span class="text-sm font-bold tracking-wider uppercase text-secondary" *ngIf="sec.date">{{sec.date}}</span>
                        <div class="flex flex-row items-start mt-3 gap-2" *ngFor="let des of sec.description">
                            <i class="fas fa-check-circle mt-1 text-green-500"></i>
                            <p class="ml-2"> {{des}}</p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</s-modal>