/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    /*{
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },*/
    // {
    //     id   : 'summary',
    //     title: 'Summary',
    //     type : 'basic',
    //     icon : 'heroicons_outline:desktop-computer',
    //     link : '/summary'
    // },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'feather:users',
        link: '/users'
    },
    {
        id: 'ppm',
        title: 'PPM',
        type: 'collapsable',
        icon: 'support',
        children: [
            {
                id: 'template',
                title: 'Template',
                type: 'basic',
                link: '/preventive/template'
            },
            {
                id: 'schedule',
                title: 'Schedule',
                type: 'basic',
                link: '/preventive/schedule'
            },
            // {
            //     id: 'assignschedule',
            //     title: 'Assign Schedule',
            //     type: 'basic',
            //     link: '/preventive/assign-schedule'
            // },
            {
                id: 'checklist',
                title: 'Check List',
                type: 'basic',
                link: '/preventive/checklist'
            },
            {
                id: 'wo',
                title: 'Work Order',
                type: 'basic',
                link: '/preventive/wo'
            }]
    },
    {
        id: 'corrective',
        title: 'CM',
        type: 'collapsable',
        icon: 'support_agent',
        children: [
            {
                id: 'maintaince-requests',
                title: 'Maintaince Requests',
                type: 'basic',
                link: '/corrective/maintenance'
            },
            {
                id: 'wo',
                title: 'Work Order',
                type: 'basic',
                link: '/corrective/work-order'
            }]
    },
    {
        id: 'dispath',
        title: 'Dispath Boards',
        type: 'collapsable',
        icon: 'import_export',
        children: [
            {
                id: 'manage',
                title: 'Manage Dispaths',
                type: 'basic',
                link: '/dispatch/manage'
            },
            {
                id: 'settings',
                title: 'Dispath Board Settings',
                type: 'basic',
                link: '/dispatch/settings'
            }]
    },
    {
        id: 'resources',
        title: 'Resources',
        type: 'collapsable',
        icon: 'transfer_within_a_station',
        children: [
            {
                id: 'labour',
                title: 'Labour Resources',
                type: 'basic',
                link: '/resources/labour'
            },
            {
                id: 'settings',
                title: 'Equipment Resources',
                type: 'basic',
                link: '/resources/equipment'
            }]
    },
    {
        id:'assets',
        title : 'Assets Management',
        type : 'collapsable',
        icon : 'storefront',
        children : [
            {
                id :'assets',
                title : 'Assets',
                type : 'basic',
                link : '/assets/all-assets'
            },
        ],
    },
    {
        id: 'store-management',
        title: 'Store Management',
        type: 'collapsable',
        icon: 'storefront',
        children: [
            {
                id: 'stock',
                title: 'Stock',
                type: 'basic',
                link: '/store-management/stock'
            },
            {
                id: 'managestore',
                title: 'Manage Store',
                type: 'basic',
                link: '/store-management/managestore'
            },
            {
                id: 'managestock',
                title: 'Manage Store Stock',
                type: 'basic',
                link: '/store-management/managestock'
            }
        ]
    },
    {
        id: 'masterdata',
        title: 'Master Data',
        type: 'basic',
        icon: 'heroicons_outline:document-add',
        link: '/masters'

    },
    {
        id: 'auditlogs',
        title: 'Audit Logs',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/auditlogs'

    },
    // {
    //     id   : 'preventive',
    //     title: 'Work Order',
    //     type : 'basic',
    //     icon : 'schedule',
    //     link : '/preventive'
    // },
    // {
    //     id   : 'manage',
    //     title: 'Add/Manage',
    //     type : 'basic',
    //     icon : 'heroicons_outline:document-add',
    //     link : '/masters'
    // },
    // {
    //     id   : 'training',
    //     title: 'Training',
    //     type : 'basic',
    //     icon : 'model_training',
    //     link : '/training'
    // },{
    //     id   : 'incidents',
    //     title: 'Incidents',
    //     type : 'basic',
    //     icon : 'report_problem',
    //     link : '/incidents'
    // },{
    //     id   : 'lost_found',
    //     title: 'Lost & Found',
    //     type : 'basic',
    //     icon : 'emoji_objects',
    //     link : '/lost-found'
    // },{
    //     id   : 'key',
    //     title: 'Key Management',
    //     type : 'basic',
    //     icon : 'feather:key',
    //     link : '/key-management'
    // },
    // {
    //     id   : 'handover',
    //     title: 'Handover/Takeover',
    //     type : 'basic',
    //     icon : 'assignment_ind',
    //     link : '/handover'
    // },
    // {
    //     id   : 'stadiums',
    //     title: 'Stadiums',
    //     type : 'basic',
    //     icon : 'games',
    //     link : '/stadiums'
    // }

];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
