import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/project'
    {path: '', pathMatch : 'full', redirectTo: 'sign-in'},

    // Redirect signed-in user to the '/dashboard'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'users'},

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'asset-details', loadChildren: () => import('app/modules/admin/asset-details/asset-details.module').then(m => m.AssetDetailsModule)},
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            // {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'summary', loadChildren: () => import('app/modules/admin/status/status.module').then(m => m.StatusModule)},
            {path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {path: 'project', loadChildren: () => import('app/modules/admin/project/project.module').then(m => m.ProjectModule)},
            {path: 'health-status', loadChildren: () => import('app/modules/admin/health/health.module').then(m => m.HealthModule)},
            {path: 'control', loadChildren: () => import('app/modules/admin/control/control.module').then(m => m.ControlModule)},
            {path: 'alerts', loadChildren: () => import('app/modules/admin/alerts/alerts.module').then(m => m.AlertsModule)},
            {path: 'settings', loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule)},
            
            {path: 'users', loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule)},
            {path: 'roles', loadChildren: () => import('app/modules/admin/roles/roles.module').then(m => m.RolesModule)},
            {path: 'training', loadChildren: () => import('app/modules/admin/training/training.module').then(m => m.TrainingModule)},
            {path: 'incidents', loadChildren: () => import('app/modules/admin/incidents/incidents.module').then(m => m.IncidentsModule)},
            {path: 'lost-found', loadChildren: () => import('app/modules/admin/lost-found/lost-found.module').then(m => m.LostFoundModule)},
            {path: 'key-management', loadChildren: () => import('app/modules/admin/key-management/key-management.module').then(m => m.KeyManagementModule)},
            {path: 'handover', loadChildren: () => import('app/modules/admin/handover/handover.module').then(m => m.HandoverModule)},
            {path: 'stadiums', loadChildren: () => import('app/modules/admin/stadiums/stadiums.module').then(m => m.StadiumsModule)},

            {path: 'assets', loadChildren: () => import('app/modules/admin/assets/assets.module').then(m => m.AssetsModule)},
            {path: 'inventory', loadChildren: () => import('app/modules/admin/inventory/inventory.module').then(m => m.InventoryModule)},
            {path: 'corrective', loadChildren: () => import('app/modules/corrective/corrective.module').then(m => m.CorrectiveModule)},
            {path: 'preventive', loadChildren: () => import('app/modules/preventive/preventive.module').then(m => m.PreventiveModule)},
            {path: 'masters', loadChildren: () => import('app/modules/admin/masters/masters.module').then(m => m.MastersModule)},
            {path: 'auditlogs', loadChildren: () => import('app/modules/admin/audit-logs/audit-logs/audit-logs.module').then(m => m.AuditLogsModule)},
            {path: 'dispatch', loadChildren: () => import('app/modules/dispath/dispath.module').then(m => m.DispathModule)},
            {path: 'resources', loadChildren: () => import('app/modules/resources/resources.module').then(m => m.ResourcesModule)},
            {path: 'store-management', loadChildren: () => import('app/modules/store/store.module').then(m => m.StoreModule)},
            {path: 'administration', loadChildren: () => import('app/modules/administration/administration.module').then(m => m.AdministrationModule)},

        ]
    }
];
