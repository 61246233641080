import { Component, OnInit } from '@angular/core';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { BehaviorSubject, Subject } from 'rxjs';
import { OneSignal } from 'onesignal-ngx';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent  implements OnInit
{

    contentText: any;
    showLoader = new BehaviorSubject<boolean>(false);
    loading$ = this.showLoader.asObservable();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    
    /**
     * Constructor
     */
    constructor(private _fuseSplash: FuseSplashScreenService,private oneSignal: OneSignal)
    {
    }
    ngOnInit(): void {
        // this.oneSignal.init({
        //   appId: "e4089419-0caa-42d0-b396-8ec87fe4acd5",
        //   serviceWorkerPath: 'https://assets.cyberresult.com/reports/OneSignalSDK-v16-ServiceWorker/OneSignalSDKWorker.js',
        //   serviceWorkerParam: { scope: 'https://assets.cyberresult.com/reports/OneSignalSDK-v16-ServiceWorker/' },
        //   notifyButton: {
        //     enable: true,  // Optional, shows a native notification button
        //   },
        //   allowLocalhostAsSecureOrigin: true  // Useful for local development
        // });
    
        // // Automatically ask for permission as soon as the app loads
        // this.oneSignal.isPushNotificationsEnabled((isEnabled: boolean) => {
        //   if (!isEnabled) {
        //     this.oneSignal.push(() => {
        //       this.oneSignal.showNativePrompt(); // Directly triggers the permission prompt
        //     });
        //   } else {
        //     console.log('Push notifications are already enabled!');
        //   }
        // });
      }
}
