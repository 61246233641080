import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { BaseRequestService } from 'app/_services/base.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { forEach } from 'lodash';
import { SearchPipe } from 'app/_filters/app.filter.pipe';

@Component({
    selector       : 'notifications',
    templateUrl    : './notifications.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    // notifications: Notification[];
    notifications: any;
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    user : any ;
    searchTerm: string = '';
    masterNotifications : any ;
    commentsData : any ;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private userservice : UserService,
        private baseService : BaseRequestService,
        private toast : MyToastrService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.userservice.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user : User) => {
            this.user = user;
            this._changeDetectorRef.detectChanges();
            
        })
        // Subscribe to notification changes
        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: Notification[]) => {

                // Load the notifications
                this.notifications = notifications;

                // Calculate the unread count
                this._calculateUnreadCount();
                this.calleach90Sec();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    calleach90Sec(){
        setTimeout(()=> {
            this.getComments();
        }, 1000);
        
        // setTimeout(() => {
        //   this.calleach90Sec()
        // }, 100000);
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void
    {
        // Return if the notifications panel or its origin is not defined
        if ( !this._notificationsPanel || !this._notificationsOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void
    {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void
    {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void
    {
        // Toggle the read status
        // notification.read = !notification.read;
        notification.read ? this.markAsUnread(notification):this.markAsRead(notification);

        // Update the notification
        // this._notificationsService.update(notification.id, notification).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void
    {

        // Delete the notification
        // this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                                  .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                                  .withLockedPosition(true)
                                  .withPush(true)
                                  .withPositions([
                                      {
                                          originX : 'start',
                                          originY : 'bottom',
                                          overlayX: 'start',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'start',
                                          originY : 'top',
                                          overlayX: 'start',
                                          overlayY: 'bottom'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'bottom',
                                          overlayX: 'end',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'top',
                                          overlayX: 'end',
                                          overlayY: 'bottom'
                                      }
                                  ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.notifications && this.notifications.length )
        {
            count = this.notifications.filter(notification => !notification.read).length;
        }

        // this.unreadCount = count;
    }

    getComments(search? : any):void{
        this.notifications = [];
        let params = {
            skip : 0,
            limit : 10000,
            sort : [{"c.keyword":{"order":"desc"}}],
            q : ''
        };
        var q: any = {
            "query": {
              "bool": {
                "must": [
                    { "match": { "user_type.keyword": `${this.user.user_data_complete.user_type}` } },
                ]
              }
            }
          };
          params.q = q;
        if(search){
            var q: any = {
                "query": {
                  "bool": {
                    "must": [
                        { "query_string": { "user_type.keyword": `${this.user.user_data_complete.user_type}` } },
                    ]
                  }
                }
              };
              var fields = ["wo_no","comment","semder_name","sender_mail"];
              let qval = search.replace(/([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\.\@\/\"])/g, '\\$1');
              if (qval.indexOf('\\') === -1) {
                qval = '*' + qval + '*';
              }
              q.query.bool.must[1].push({ 'query_string': { "query": qval, "fields": fields } });
            //   q.query.bool.must.push(' "match": { "type.keyword": "commentshmc"}');
              params.q = q;
        }
        this.baseService.doRequest('/api/commentshmc', 'get', null, params).subscribe(result => {
            if(result && result.total > 0){
                // if(search && search.length > 0){
                //     result.data = this.searchPipe.transform(result.data, search);
                // }

                // Sorting in Ascending Order asper Sender Name
                result.data.sort((a, b) => {
                    if (a.semder_name < b.semder_name) {
                        return -1;
                    }
                    if (a.semder_name > b.semder_name) {
                        return 1;
                    }
                    return 0;
                });
                
                console.log('After Sorting',result.data);
                let users = result.data.map((item: any) => item.userread);

                let read3 = []; //True / False 
                // this.user.email = 'testhmc008@yopmail.com';
                for (let i = 0; i < users.length ; i++){
                    read3[i] = users[i].includes(this.user.email);
                }
                let counts = read3.reduce((acc, val) => {
                    if (val) {
                        acc.trueCount++;
                    } else {
                        acc.falseCount++;
                    }
                    return acc;
                }, { trueCount: 0, falseCount: 0 });

                this.unreadCount = counts.falseCount;

                for (let i = 0 ; i<read3.length ; i++){
                    if(read3[i] === true){
                        result.data[i]['read'] = true;
                    }
                    else if (read3[i] === false){
                        result.data[i]['read'] = false;
                    }
                }

                result.data.sort((a, b) => {
                    return a.read === b.read ? 0 : a.read ? 1 : -1;
                });                


                // let read2 = users2.includes(this.user.email)
                // let read = users.includes(this.user.email);
                for(let i = 0; i<result.data.length ; i++ ){
                    let notification = {
                        image : false,
                        title : result.data[i].wo_no,
                        description : result.data[i].comment,
                        sender : result.data[i].semder_name,
                        sender_mail : result.data[i].sender_mail,
                        time : result.data[i].c,
                        read : result.data[i].read,
                        _id : result.data[i]._id,
                        userread : result.data[i].userread
                    }
                    this.notifications.push(notification);
                }
                this._changeDetectorRef.detectChanges();
                this.commentsData = result.data;
                this.masterNotifications = this.notifications;

            }
            else {
                this.toast.sToast('Error', 'Something Went Wrong');
            }
        });
    }

    filterCompany (): void{
        if (!this.searchTerm) {
            // this.notifications = this.masterNotifications;
            this.getComments();
        }
        else {
            let lowerSearchTerm = this.searchTerm.toLowerCase();
            this.getComments(lowerSearchTerm);
            // let a = this.masterNotifications.map((data : any) => data.title);
            // let b  = a.filter((title: any) => title.toLowerCase().includes(lowerSearchTerm));
            // this.notifications = this.masterNotifications.filter(notification => b.includes(notification.title));

            
            // this.notifications = b;
            this._changeDetectorRef.detectChanges();
        }
    }

    markAsRead(data:any): void{
        let params = {
            _id : data._id,
            userread : ''
        } ;
        params._id = data._id,
        data.userread.push(this.user.email);
        params.userread = data.userread;
        this.baseService.doRequest('/api/commentshmc', 'put', params).subscribe(
            (result: any) => {
            if(result){
                this.getComments();
            } 
            else {
                this.toast.sToast('error', 'Unable to Change the Read Status');
            }
            },
            ((error : any ) =>{
                this.toast.sToast('error', 'Unable to Change the Comment Status');
            })
            )
    }

    markAsUnread(data : any ): void{
        let params = {
            _id : data._id,
            userread : ''
        };
        if(data.userread.includes(this.user.email)){
            data.userread = data.userread.filter(email => email !== this.user.email);
        }
        params.userread = data.userread;

        this.baseService.doRequest('/api/commentshmc', 'put', params).subscribe(
            (result: any) => {
            if(result){
                this.getComments();
            } 
            else {
                this.toast.sToast('error', 'Unable to Change the Read Status');
            }
            },
            ((error : any ) =>{
                this.toast.sToast('error', 'Unable to Change the Comment Status');
            })
            )


    }
}
