<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-primary-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <ng-container fuseVerticalNavigationContentHeader>
                <!-- Logo -->
                <div class="flex items-center justify-center mx-2 lg:mr-8 mt-2">
                    <div class="hidden lg:flex">
                        <!-- Light version -->
                        <img
                            class="dark:hidden  max-w-[12rem] h-[2.5rem] object-contain"
                            src="assets/logohamad.png" aria-label="light"
                            alt="Logo image">
                        <!-- Dark version -->
                        <img
                            class="hidden dark:flex  max-w-[12rem] h-[2.5rem] object-contain"
                            src="assets/logohamad.png" aria-label="dark"
                            alt="Logo image">
                    </div>

                    <!-- Small version -->
        
                    <div class="flex lg:hidden">
                        <!-- Light version -->
                        <img
                            class="dark:hidden  max-w-[10rem] h-[2.5rem] object-contain"
                            src="assets/logohamad.png" aria-label="light"
                            alt="Logo image">
                        <!-- Dark version -->
                        <img
                            class="hidden dark:flex  max-w-[10rem] h-[2.5rem] object-contain"
                            src="assets/logohamad.png" aria-label="dark"
                            alt="Logo image">
                    </div>
        
                </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <!-- <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button> -->
        <!-- <div class="text-primary-600 text-2xl font-bold flex flex-row gap-2"><img class="w-[40px] h-[40px]" src="../../../../../favicon-32x32.png" alt="360">HAMAD MEDICAL CORPORATION</div> -->
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"><div *ngIf="modalService.loading$ | async " class="loader-class custom-class">
        <svg class="animate-spin -ml-1 mb-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
 </svg>
        <p *ngIf="!modalService.contentText || modalService.contentText === ''">Loading... </p>
        <p class="m-0">{{modalService.contentText}}</p>
      </div></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 items-center justify-end w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">HAMAD MEDICAL CORPORATION  &copy; {{currentYear}}</span>
    </div>

</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
